import React from "react";
import { graphql, useStaticQuery } from "gatsby";
import BaseLayout from "../../../components/layout"
import Seo from "../../../components/seo";
import BookPageHeader from "../../../components/book-page-header";
import TextBlock from "../../../components/text-block";
import HorizontalRule from "../../../components/horizontal-rule";
import BookTOCCard from "../../../components/book-toc-card";
import ChapterSummary from "../../../components/chapter-summary";

export default function WritingNomadLifePage(props) {
  let data = useStaticQuery(query)
  const lostInSeaBook = data.lostInSea.nodes[0];
  const lostInSeaChapters = data.allMdx.nodes;

  let breadcrumbList = [
    [
      {
        title: 'Home',
        url: '/'
      },
      {
        title: 'Library',
        url: '/writing/library/'
      },
      {
        title: 'Lost in a Sea of Words',
      }
    ]
  ]

  return (
    <BaseLayout>
      <Seo title="Lost in a Sea of Words" desc={lostInSeaBook.seo_description} pathname={props.location.pathname} breadcrumbList={breadcrumbList}></Seo>

      <BookPageHeader book={lostInSeaBook} chapters={lostInSeaChapters} breadcrumbTrail={breadcrumbList[0]}></BookPageHeader>

      <div className="layout-base-w app-px-base">
        <div className="mt-40">
          <h3 className="text-header-2 text-mint text-center mb-12">{lostInSeaBook.title}</h3>

          <div className="layout-reading-w mt-12 mb-12">
            <TextBlock text={lostInSeaBook.summary}></TextBlock>
          </div>

          <div className="my-40 text-mint">
            <HorizontalRule ruleType="4"></HorizontalRule>
          </div>

          <div className="my-40">
            <BookTOCCard chapters={lostInSeaChapters}></BookTOCCard>
          </div>

          <div className="grid grid-cols-1 md:grid-cols-2 gap-x-8 gap-y-40 my-40">
            {lostInSeaChapters.map((chapter, index) => (
              <ChapterSummary key={index} chapter={chapter}></ChapterSummary>
            ))}
          </div>

          <div className="my-40 text-mint">
            <HorizontalRule ruleType="2"></HorizontalRule>
          </div>

          <div className="flex justify-center my-40">
            <ChapterSummary chapter={lostInSeaChapters[0]} summaryType="cta"></ChapterSummary>
          </div>

        </div>
      </div>
    </BaseLayout>
  )
}

export const query = graphql`
  query WritingSeaOfWords {
    allMdx(
      sort: { fields: [frontmatter___chapter], order: ASC }
      filter: { frontmatter: { published: { eq: true }, record_type: { eq: "story" }, collection: { eq: "Lost in a Sea of Words" } } }
    ) {
      nodes {
        ...ArticleSnippet
      }
    }
    
    lostInSea: allCollections(
      filter: { title: { eq: "Lost in a Sea of Words" } }
      limit: 1
    ) {
      nodes {
        ...CollectionFields
      }
    }
  }
`;
